import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';
import { PatientsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { ContactAddressDto2, ContactEmailDto2, ContactPhoneDto2, NotificationAddressDto, NotificationDto, NotificationEmailDto, NotificationPhoneDto, PatientClient, PatientNotifyTypeEnum, RelationshipDto, SecureAccessDto, PhoneTypeEnum, EmailTypeEnum, PatientNotifySubscriptionTypeEnum } from 'src/app/shared/services/api.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { FutureMessagesDialogComponent } from 'src/app/dialogs/future-messages-dialog/future-messages-dialog.component';
import emailMask from 'text-mask-addons/dist/emailMask';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { faCommentSlash } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-patient-notification-modal',
  templateUrl: './patient-notification-modal.component.html',
  styleUrls: ['./patient-notification-modal.component.scss']
})
export class PatientNotificationModalComponent implements OnInit, OnDestroy {
  @Input() isNewPatient;
  @Input() submitclick;
  @Input() selectedPatientId;
  @Output() aftersave = new EventEmitter();
  @Output() hasDuplicateEmail = new EventEmitter();

  notifications: NotificationDto[] = [];
  relationships: RelationshipDto[];
  modified: boolean = false;
  dialog: DialogComponent;
  isWorking: boolean = false;

  PatientNotifyTypeEnum = PatientNotifyTypeEnum;
  selectedNotificationItem:NotificationDto;

  @ViewChild('secureAccessDialog', {static: false}) secureAccessDialog:TemplateRef<any>;
  @ViewChild('emailPhoneDialog', {static: false}) emailPhoneDialog:TemplateRef<any>;
  @ViewChild('deleteTemplate', { static: true }) deleteTemplate: TemplateRef<any>;
  @ViewChild('deletePhoneTemplate', { static: true }) deletePhoneTemplate: TemplateRef<any>;

  secureAccessDialogRef: MatDialogRef<any>;
  emailPhoneDialogRef: MatDialogRef<any>;

  secureAccesses: SecureAccessDto[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedPatientId$: Observable<number> = this._store$.select(PatientsStoreSelectors.getSelectedPatientId);
  patientId: number;
  emailMask = emailMask;
  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneTypeList = [...Object.entries(PhoneTypeEnum).map(([_, value]) => ({name: value, isDisabled: false})).filter(key => key.name != PhoneTypeEnum.Fax)];
  emailForm:FormGroup;
  phoneForm:FormGroup;
  isEditEmailPhone:boolean = false;

  isPhoneDialog:boolean = false;

  faCommentSlash = faCommentSlash;
  patientNotifySubscriptionTypeEnum = PatientNotifySubscriptionTypeEnum;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _patientsClient: PatientClient,
    private matDialog: MatDialog,
    private formBuilder:FormBuilder,
    private _snackbar: MatSnackBar
  ) {
    this.dialog = new DialogComponent(matDialog);
  }

  ngOnInit() {

    this.emailForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      isemailAddressEditable: [''],
      isPhoneNumberEditable: [''],
    })

    this.phoneForm = this.formBuilder.group({
      phoneNumber: ['',  [Validators.required, Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
      phoneType: [''],
      isemailAddressEditable: [''],
      isPhoneNumberEditable: [''],
    })

    if (!this.selectedPatientId) {
      this.selectedPatientId$
        .pipe(
          takeUntil(this.destroy$),
          filter(patientId => !!patientId)
        ).subscribe((patientId: number) => {
          this.patientId = patientId;
          this.initializeNotifications();
        });
    }
    else {
      this.patientId = this.selectedPatientId;
      this.initializeNotifications();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  initializeNotifications(): void {
    this.notifications = null;
    this.modified = false;
    this.isWorking = true;
    combineLatest([
      this._patientsClient.patient_GetNotificationsByPatient(this.patientId),
      this._patientsClient.patient_GetRelationships(this.patientId)
    ])
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([notifications, relationships]) => {
        this.relationships = relationships;
        this.notifications = notifications;
        let emails: string[] = [];

        _.each(this.relationships, (r: RelationshipDto) => {
          let notification = this.notifications.find((x) => { return x.contactId == r.contactId });
          if (!notification) {
            notification = this.generateNotification(r);

            if (r.mobilePhone && r.mobilePhone.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.mobilePhone);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.alternatePhone1 && r.alternatePhone1.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.alternatePhone1);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.alternatePhone2 && r.alternatePhone2.id > 0) {
              let phoneNotification: NotificationPhoneDto = this.generatePhoneNotification(r, r.alternatePhone2);
              if (notification)
                notification.phones.push(phoneNotification);
            }

            if (r.primaryEmail && r.primaryEmail.id > 0) {
              let emailNotification: NotificationEmailDto = this.generateEmailNotification(r, r.primaryEmail);
              if (emailNotification)
                notification.emails.push(emailNotification);
            }

            if (r.primaryAddress && r.primaryAddress.id > 0) {
              let addressNotification: NotificationAddressDto = this.generateAddressNotification(r, r.primaryAddress);
              if (addressNotification)
                notification.addresses.push(addressNotification);
            }

            notification.emails.map((email: any) => {
              email.isShow = false;
            })

            notification.phones.map((phone: any) => {
              phone.isShow = false;
            })

            this.notifications.push(notification);
          }
        });

        this.notifications = _.sortBy(this.notifications, ['lastName', 'firstName']);

        emails = _.chain(this.notifications)
          .reduce((accumulator, value) => accumulator.concat(value.emails), [])
          .map('emailAddress')
          .value();

        let uniqueEmails = [] = _.uniq(emails);
        this.hasDuplicateEmail.emit(uniqueEmails.length != emails.length);
        this.isWorking = false;        
      });
  }

  generateNotification(relationship: RelationshipDto): NotificationDto {
    let notification: NotificationDto = new NotificationDto();

    notification.patientId = relationship.patientId;
    notification.contactId = relationship.contactId;
    notification.title = relationship.title;
    notification.firstName = relationship.firstName;
    notification.lastName = relationship.lastName;
    notification.relationshipType = relationship.relationshipType;
    notification.relationshipTypeLabel = relationship.relationshipTypeLabel;
    notification.relationshipTypeAbbreviation = relationship.relationshipTypeCode;
    notification.phones = [];
    notification.emails = [];
    notification.addresses = [];

    return notification;
  }

  generateEmailNotification(relationship: RelationshipDto, contactEmail: ContactEmailDto2): NotificationEmailDto {
    let email = new NotificationEmailDto();

    email.contactEmailId = contactEmail.id;
    email.emailType = contactEmail.emailType;
    email.emailAddress = contactEmail.emailAddress;
    email.isSecureAccess = relationship.isSecureAccess;

    if(relationship.relationshipType == 'Patient') {
      email.forPatientForm = true;
    }

    return email;
  }

  generatePhoneNotification(relationship: RelationshipDto, contactPhone: ContactPhoneDto2): NotificationPhoneDto {
    let phone = new NotificationPhoneDto();

    phone.contactPhoneId = contactPhone.id;
    phone.phoneType = contactPhone.phoneType;
    phone.phoneNumber = contactPhone.number;

    return phone;
  }

  generateAddressNotification(relationship: RelationshipDto, contactAddress: ContactAddressDto2): NotificationAddressDto {
    let address = new NotificationAddressDto();

    address.contactAddressId = contactAddress.id;
    address.addressType = contactAddress.addressType;
    address.addressLine1 = contactAddress.addressLine1;
    address.addressLine2 = contactAddress.addressLine2;
    address.city = contactAddress.city;
    address.state = contactAddress.state;
    address.zip = contactAddress.zip;

    return address;
  }

  ngOnChanges(){
    if(this.submitclick){
      this.save();
    }
  }

  save() {
    this.modified = false;
    this.submitclick = false;
    this.isWorking = true;

    this.notifications.forEach((n: any) => { n.retriggerPatientStatus = !!this.isNewPatient; })

    this.secureAccesses = [];
    let secureAccesses_nonModal = [];

    let hasSecureAccesses = _.chain(this.notifications)
      .reduce((accumulator, value) => accumulator.concat(value.emails), [])
      .filter((item: NotificationEmailDto) => (item.isSecureAccess))
      .value();

    hasSecureAccesses.map((notificationEmail: any) => {
      let _secureAccessDto = new SecureAccessDto();

      _secureAccessDto.contactId = notificationEmail.contactId;
      _secureAccessDto.isSecureAccess = notificationEmail.isSecureAccess;
      _secureAccessDto.emailAddress = notificationEmail.emailAddress;

      if (notificationEmail.willShowSecureDialog) {
        _secureAccessDto.sendConfirmation = true;
        this.secureAccesses.push(_secureAccessDto);
      } else {
        secureAccesses_nonModal.push(_secureAccessDto);
      }
    });

    if (this.secureAccesses.length > 0) {
      this.secureAccessDialogRef = this.dialog.open(this.secureAccessDialog);
      this.secureAccessDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
        let willSaveSecureAccesses: boolean = !!resp;
        this.saveNotifications(this.secureAccesses, willSaveSecureAccesses);
      })
    } else {
      this.saveNotifications(secureAccesses_nonModal, true);
    }


  }

  selectAllEmailPhoneChanged(notification: NotificationEmailDto | NotificationPhoneDto): void {
    let isChecked: boolean = notification.forAppointments &&
      notification.forTreatment &&
      notification.forFinancial &&
      notification.forMarketing &&
      notification.forBirthday &&
      notification.forPatientForm;

    notification.forAppointments = !isChecked;
    notification.forTreatment = !isChecked;
    notification.forFinancial = !isChecked;
    notification.forMarketing = !isChecked;
    notification.forBirthday = !isChecked;
    notification.forPatientForm = !isChecked;

    this.modified = true;
  }

  selectAllAddressChanged(notification: NotificationAddressDto): void {
    let isChecked: boolean = notification.forMarketing &&
      notification.forGifting;

    notification.forMarketing = !isChecked;
    notification.forGifting = !isChecked;

    this.modified = true;
  }

  selectCheckbox(notification, checkboxFor?:string){
    this.modified = true;

    setTimeout(() => {
      notification.selectAll = notification.forAppointments &&
        notification.forTreatment &&
        notification.forFinancial &&
        notification.forMarketing &&
        notification.forBirthday &&
        notification.forPatientForm;
    });
  }

  checkFutureMessages(){
    this.isWorking = true;
    this._patientsClient.patient_GetMessages(this.patientId, undefined, -1).subscribe(resp => {
      let _messages = resp.filter(message => message.messageType != 'Notification');
      if (_messages.length > 0) {
        const dialogRef = this.matDialog.open(FutureMessagesDialogComponent, { data: { patientId: this.patientId } })
      }
      this.isWorking = false;
    }, err => {
      this.isWorking = false;
    });
  }

  saveNotifications(secureAccesses, willSaveSecureAccesses?: boolean) {
    combineLatest([
      this._patientsClient.patient_PostNotifications(this.patientId, this.notifications),
      willSaveSecureAccesses ? this._patientsClient.patient_PostContactSecureAccess(this.patientId, secureAccesses) : of(null)
    ])
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError((err, caught) => {
          this.modified = true;
          this.isWorking = false;
          let data = {
            message: err.message
          };

          if (err.error == 'Duplicate email.') {
            data.message = `You have selected to make this email secure. Another person already is using this
                email as a secure email. Please use another email.`;
          }

          this.dialog.open(MessageDialog, data);
          return EMPTY;
        })
      )
      .subscribe((result) => {
        this.isWorking = false;
        this.aftersave.emit(result);
      });
  }

  onChangeSecureAccess(evt, notification){
    notification.willShowSecureDialog = evt.checked
  }

  onAddEditEmailPhone(notification, isEditMode, rowIndx?, isPhone?:boolean){

    this.selectedNotificationItem = notification;
    this.isEditEmailPhone = isEditMode;
    this.isPhoneDialog = isPhone ? isPhone : false;
    
    if(isEditMode){
      if(isPhone){
        this.phoneForm.patchValue({
          phoneNumber: (notification.phones.length > 0 && notification.phones[rowIndx].phoneNumber) ? notification.phones[rowIndx].phoneNumber : null,
          phoneType: (notification.phones.length > 0 && notification.phones[rowIndx].phoneType) ? notification.phones[rowIndx].phoneType : null,
          isPhoneNumberEditable: true,
        })
      } else {
        this.emailForm.patchValue({
          emailAddress: (notification.emails.length > 0 && notification.emails[rowIndx].emailAddress) ? notification.emails[rowIndx].emailAddress : null,
          isemailAddressEditable: true,
        })
      }
    } else {
      let _isemailAddressEditable: boolean = false;
      let _isPhoneNumberEditable: boolean = false;

      let notificationPhones;
      let phoneType = PhoneTypeEnum.Mobile;

      //let _phoneItem: any = null;

      if(notification.phones.length > 0){        
        if(notification.phones.length == 3){
          notificationPhones = notification.phones[0];
          phoneType = notificationPhones.phoneType;
          this.phoneTypeList.map(_phoneType => {
            
            let notificationPhoneType =  notification.phones.find(phoneItem => phoneItem.phoneType ==  _phoneType.name);
            if(notificationPhoneType){
              _phoneType.isDisabled = false;
            } else {
              _phoneType.isDisabled = true;
            }
          })


          _isPhoneNumberEditable = false;
        } else {

          this.phoneTypeList.map(item => {
            item.isDisabled = false;
          })

          for (let i = 0; i < this.phoneTypeList.length; i++) {
            phoneType = this.phoneTypeList[i].name;
            notificationPhones = notification.phones.find(phone => phone.phoneType == phoneType);
            if (notificationPhones == undefined) {
              break;
            }
          }
          
          if(notificationPhones){
            _isPhoneNumberEditable = false;
          } else {
            _isPhoneNumberEditable = true;
          }        
        }

        
      } else {
        _isPhoneNumberEditable = true;
      }

      this.phoneForm.patchValue({
        phoneNumber: notificationPhones ? notificationPhones.phoneNumber : null,
        phoneType: notificationPhones ? notificationPhones.phoneType : phoneType,
        isPhoneNumberEditable: _isPhoneNumberEditable,
      })

      if(notification.emails.length > 0 && notification.emails[0].emailAddress){
        _isemailAddressEditable = false;
      } else {
        _isemailAddressEditable = true;
      }
      
      this.emailForm.patchValue({
        emailAddress: (notification.emails.length > 0 && notification.emails[0].emailAddress) ? notification.emails[0].emailAddress : null,
        isemailAddressEditable: _isemailAddressEditable,
      })      
    }
    

    this.emailPhoneDialogRef = this.matDialog.open(this.emailPhoneDialog);

    this.emailPhoneDialogRef.afterClosed().pipe(take(1)).subscribe(resp => {
      if(resp){
        if(this.isPhoneDialog){
          this._patientsClient.patient_GetRelationshipPhones(this.selectedNotificationItem.patientId, this.selectedNotificationItem.contactId).subscribe(resp => {
            this.updatePhones(resp);
          })
        } else {
          this._patientsClient.patient_GetRelationshipEmails(this.selectedNotificationItem.patientId, this.selectedNotificationItem.contactId).subscribe(resp => {
            this.updateEmail(resp);
          })
        }
      }
    })
  }

  onChangePhoneTypeSelection(evt){
    let phones = this.selectedNotificationItem.phones;
    let phoneItem = phones.find(item => item.phoneType == evt.value);

    let _isPhoneNumberEditable:boolean = false;

    if(this.isEditEmailPhone){
      _isPhoneNumberEditable = true;
    } else {
      
      if(phoneItem && phoneItem.phoneNumber){
        _isPhoneNumberEditable = false;
      } else {
        _isPhoneNumberEditable = true;
      }
    }

    this.phoneForm.patchValue({
      phoneNumber: phoneItem ? phoneItem.phoneNumber : null,
      phoneType: evt.value,
      isPhoneNumberEditable: _isPhoneNumberEditable
    })

  }

  clearPhones(phoneNumber) {
    return phoneNumber.replace(/[()-]/g, '').replace(/\s/g,'')
  }

  updateEmail(resp){
    let relationShipEmail:ContactEmailDto2 = new ContactEmailDto2();

    if(resp.length > 0){
      relationShipEmail = resp[0];
      relationShipEmail.emailAddress = this.emailForm.value.emailAddress;

      this._patientsClient.patient_PutRelationshipEmail(
        this.selectedNotificationItem.patientId, 
        this.selectedNotificationItem.contactId, 
        relationShipEmail.id, 
        relationShipEmail, 
        null, 
        relationShipEmail.eTag
      ).subscribe(resp => {
        this.initializeNotifications();
      }, 
      err => {
        this.openSnackBar(err.message, "OK");
      })

    } else {
      relationShipEmail.emailAddress = this.emailForm.value.emailAddress;
      relationShipEmail.emailType = EmailTypeEnum.Personal;

      this._patientsClient.patient_PostRelationshipEmail(
        this.selectedNotificationItem.patientId, 
        this.selectedNotificationItem.contactId,
        relationShipEmail 
      ).subscribe(resp => {
        this.initializeNotifications();
      }, 
      err => {
        this.openSnackBar(err.message, "OK");
      })
    }
  }

  updatePhones(resp){
    let contactPhoneDto: ContactPhoneDto2 = new ContactPhoneDto2();

    let emailPhoneFormValue = this.phoneForm.value;

    if(resp.length > 0){

      let _contactPhoneDto = resp.find(phoneItem => phoneItem.phoneType == emailPhoneFormValue.phoneType);

      if(_contactPhoneDto){
        contactPhoneDto = _contactPhoneDto;
        contactPhoneDto.number = this.clearPhones(emailPhoneFormValue.phoneNumber);

        this._patientsClient.patient_PutRelationshipPhone(
          this.selectedNotificationItem.patientId, 
          this.selectedNotificationItem.contactId,
          contactPhoneDto.id,
          contactPhoneDto,
          null,
          contactPhoneDto.eTag 
        ).subscribe(resp => {
          this.initializeNotifications();
        }, 
        err => {
          this.openSnackBar(err.message, "OK");
        })
      } else {
        contactPhoneDto.number = this.clearPhones(emailPhoneFormValue.phoneNumber);
        contactPhoneDto.phoneType = emailPhoneFormValue.phoneType;
        this._patientsClient.patient_PostRelationshipPhone(
          this.selectedNotificationItem.patientId, 
          this.selectedNotificationItem.contactId,
          contactPhoneDto,
        ).subscribe(resp => {
          this.initializeNotifications();
        }, 
        err => {
          this.openSnackBar(err.message, "OK");
        })
      }

    } else {
      contactPhoneDto.number = this.clearPhones(emailPhoneFormValue.phoneNumber);
      contactPhoneDto.phoneType = emailPhoneFormValue.phoneType;
      this._patientsClient.patient_PostRelationshipPhone(
        this.selectedNotificationItem.patientId,
        this.selectedNotificationItem.contactId,
        contactPhoneDto,
      ).subscribe(resp => {
        this.initializeNotifications();
      }, 
      err => {
        this.openSnackBar(err.message, "OK");
      })
    }
  }

  deleteEmail(email:NotificationEmailDto, notification ) {
    this.selectedNotificationItem = notification;
		this.matDialog.open(this.deleteTemplate).afterClosed().subscribe((result) => {
			if (result) {
        this.confirmDelete(email);
			}
		});
	}

  confirmDelete(email:NotificationEmailDto ){
    this._patientsClient.patient_DeleteRelationshipEmail(
      this.selectedNotificationItem.patientId,
      email.contactId,
      email.contactEmailId
    ).subscribe(resp => {
      this.initializeNotifications();
      this.openSnackBar("Successfully deleted!", "");
    }, 
    err => {
      this.openSnackBar(err.message, "OK");
    })
  }

  deletePhone(phone:NotificationPhoneDto, notification ) {
    this.selectedNotificationItem = notification;
		this.matDialog.open(this.deletePhoneTemplate).afterClosed().subscribe((result) => {
			if (result) {
        this.confirmDeletePhone(phone);
			}
		});
	}

  confirmDeletePhone(phone:NotificationPhoneDto ){
    this._patientsClient.patient_DeleteRelationshipPhone(
      this.selectedNotificationItem.patientId,
      phone.contactId,
      phone.contactPhoneId
    ).subscribe(resp => {
      this.initializeNotifications();
      this.openSnackBar("Successfully deleted!", "");
    }, 
    err => {
      this.openSnackBar(err.message, "OK");
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  diabledEmailForm(){
    if(this.emailForm.value.isemailAddressEditable && this.emailForm.valid){
      return true;
    } else {
      return false;
    }

    return true;
  }
  
  disablePhoneForm(){
    if(this.phoneForm.value.isPhoneNumberEditable && this.phoneForm.valid){
      return true;
    } else {
      return false;
    }

    return true;
  }

  getEmailOrPhone(evt){
    this.isPhoneDialog = evt.value;    
  }

}
