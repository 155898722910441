import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from 'src/app/core/message.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { LocationService } from 'src/app/shared/services';
import { CommonApiService } from 'src/app/shared/services/new-appnt-service/common-api.service';
import { LANGUAGES_LIST } from 'src/app/shared/enums';
import {
	LocationClient,
	SettingsClient,
	ProviderClient,
	DentistClient,
	PatientClient,
	ClinicClient,
	ContactClient,
	EmployerDto,
	SearchClient,
	GenderEnum,
	PolicyDto,
	RelationshipTypeEnum,
	CreatePatientDto,
	ContactAddressDto2,
	PatientPhoneDto,
	PatientEmailDto,
	AlertsDto,
	RelationshipDto,
	ContactPhoneDto2,
	ContactEmailDto2
} from 'src/app/shared/services/api.service';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { PatientsStoreActions, RootStoreState, LocationsStoreSelectors, LocationsStoreActions, AuthStoreSelectors } from 'src/app/root-store';
import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as moment from 'moment-timezone';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-create-new-patient',
  templateUrl: './create-new-patient.component.html',
  styleUrls: ['./create-new-patient.component.css']
})
export class CreateNewPatientComponent implements OnInit, OnDestroy {


	@Output() showCreateAppnt = new EventEmitter();
	@Output() showProcedureEvent = new EventEmitter();
	@Output() passPatientInfo = new EventEmitter();
	@Output() onAbandon = new EventEmitter();
	@Output() afterSubmitPatient = new EventEmitter();

	emailMask = emailMask;

	@Input() patientPopUpOpen: any;
	@Input() selectedLocation: any;

	responsibleBackup: any = {};

	preferredPracArr: any[] = [{ "id": '', "option": "Preferred Location" }];

	preferredDoctorArr: any[] = [{ "id": '', "option": "Preferred Doctor" }];

	genderArr: any[] = [];
	Gender = GenderEnum;

	private includeLanguages: string[] = ['en', 'es'];
	LANGUAGES_LIST = _.pickBy(LANGUAGES_LIST, (value, key) => {
		return this.includeLanguages.includes(key);
	  });


	phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	dobmask: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
	ssnmask: any = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	zipcodeMask: any = [/\d/, /\d/, /\d/, /\d/, /\d/];
	statemask: any = [/[a-zA-Z]/, /[a-zA-Z]/];

	showConfirmation: boolean = false;
	showResponsibleParty_modal: boolean = false;

	errorMessage = 'Are you sure you want to abandon patient information before saving?';
	abandonButton: boolean = false;


	showPatientResult: boolean = false;
	filterPatient: any[] = [];

	patient_data: any = [];

	searchEnable: boolean = true;
	getSearchData: boolean = false;
	patientId: any = '';
	contactId: any = '';
	patient: any = {};
	treatmentStatusArr: any[] = [];
	showrerrefProf: boolean = false;
	showrerrefPatient: boolean = false;
	referralProfnl: any[] = [];
	referralPatient: any[] = [];
	formValueChanged: boolean = false;
	showUpdateConfirmation: boolean = false;

	defaultLang: any = "en";
	defaultTreatmentStatus: any = '';

	panelOpenState: boolean = false;
	step: any = 0;

	accordionPanel: any[] = [{ "title": "patient information" }];
	addNewResp: boolean = false;
	showNotification_modal: boolean = false;
	multipleResp: boolean = false;
	patientRespIndx: any = 0;

	insuranceCompies: any[] = [];

	newResponsiblePartyform: FormGroup;
	patientResp: any[] = [];
	contactETag: any = '';
	addedRespCounter: any = 0;
	btnState: any = "save";
	mdclAlrtArr: any[] = [];
	filterMdclAlrtArr: any[] = [];
	professionalList: any[] = [];
	selectedProfesnl: any = {};
	showProfessnlPopup: boolean = false;
	showPatientlPopup: boolean = false;
	patientAllData: any[] = [];
	selectedReferringPatient: any = {};
	employers: EmployerDto[] = [];
	selectedInsurance: any = "";
	initSearch:boolean = false;
	searchExistingPatientArr: any[] = [];
	isExistingPatient:boolean = false;
	isSearchLoading:boolean = false;
	selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
	titleArr: { name: string;}[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
		name: value.name,
	  }));

	filterTitleArr: any[];
	isLoading: boolean;
	filterInsuranceArr: any[]=[];
	insuranceSortType:any = 'asc';
	patientPolicies: PolicyDto[] = [];
	showInsuranceSection:boolean = true;
	showAddPolicyBtn:boolean = true;
	selectedPolicy:PolicyDto;

	@ViewChild("patientsDentist", { static: false }) patientsDentist: ElementRef;
	@ViewChild("professionalthank", { static: false }) professionalthank: ElementRef;
	@ViewChild("patientthank", { static: false }) patientthank: ElementRef;

	patientForm:FormGroup;
	respPartyForm:FormGroup;
	relationshiparr: any[] = [...Object.entries(RelationshipTypeEnum).map(([key, value]) => ({id: key, name: value}))];
	newPatientData:CreatePatientDto;

	constructor(
		private formBuilder: FormBuilder,
		private dataService: MessageService,
		private locationService: LocationService,
		private locationClient: LocationClient,
		private settingsClient: SettingsClient,
		private providerClient: ProviderClient,
		private dentistClient: DentistClient,
		private patientClient: PatientClient,
		private clinicClient: ClinicClient,
		private contactClient: ContactClient,
		private scheduleService: ScheduleService,
		private apiService: CommonApiService,
		private _searchClient: SearchClient,
		private _store$: Store<RootStoreState.State>,
		private _snackbar: MatSnackBar,
	) {
		this.selectedLocation = this.scheduleService.selectedLocation;
	}

	ngOnInit() {

		this.initializePatientForm();
		this.initializeResponsiblePartyform();
		this.getLanguage();
		this.getLocations();
		this.getPatientStatusGroup();
		this.getInsurance();
		this.getMedicalAlert();
  }

	get respPartyFormControl() { return this.respPartyForm.controls; }
	get respPartyFormField() { return this.respPartyFormControl.respParties as FormArray}

  ngOnDestroy() {
  }


	initializePatientForm() {
		this.patientForm = this.formBuilder.group({
			patientStatusId: [''],
			patientFirstName: ['', Validators.required],
			patientMiddleName: [''],
			patientLastName: ['', Validators.required],
			patientPreferred: [''],
			patientTitle: [''],
			patientStreetAd: [''],
			patientCity: [''],
			patientState: [''],
			patientZipCode: [''],
			patientdob: [''],
			gender: ['', Validators.required],
			patientSsn: [''],
			patientCellPhone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
			patientEmail: ['', [Validators.email]],
			medicalAlert: [''],
			notes: [''],
			dentistId: [''],
			dentistClinicId: [''],
			referringProfessional: [''],
			referringClinicId: [''],
			preferredLocationId: [''],
			preferredProviderId: [''],
			languageKey: [''],
			insurance: [''],
			insuranceName: [''],
			groupNumber: [''],
			employerId: [''],
			insuranceNotes: [''],
			payorId: [''],
			patientEmp: [''],
		})





		this.selectedLocation$.pipe()
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				if (res != undefined) {

					this.patientForm.patchValue({
						patientCity: res.address.city,
						patientState: res.address.state
					});

				}
			});

		this.filterTitleArr = this.titleArr;
	}

	initializeResponsiblePartyform() {
		this.respPartyForm = this.formBuilder.group({
			respParties: new FormArray([])
		})

		this.addNewRespParty();

	}


	dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
	}

	cancel() {
		this.showConfirmation = true;
		this.errorMessage = 'Are you sure you want to abandon patient information before saving?';
		this.abandonButton = true;

	}

	btnAction(action) {
		if (action == 'abandon') {
			this.onAbandon.emit();
		} else {
			if (action == 'back') {
				this.showConfirmation = false;
			} else {
				if (this.patientPopUpOpen == 'emptyCell') {
					this.showProcedureEvent.emit("showProcedureEvent");
				} else {
					this.showCreateAppnt.emit("showcreateappnt");
				}

			}
		}
	}

	getLanguage() {
		this.patientForm.patchValue({
			"languageKey": this.defaultLang
		})

	}

	getLocations() {
    this.locationService.getLocations()
      .pipe(untilDestroyed(this))
      .subscribe((locations: any) => {
			this.preferredPracArr = [{ "id": '', "option": "Preferred Location" }];
			let locationsdata = locations;

			locationsdata.map(item => {
				if (item.isActive) {
					this.preferredPracArr.push({ 'id': item.id, 'option': item.shortName })
				}
			});

			this.patientForm.patchValue({
				"preferredLocationId": this.selectedLocation.id
			});

			this.getPreferredLocation(this.selectedLocation);

		});
	}

	getPatientStatusGroup() {
    this.settingsClient.settings_GetPatientStatusGroups(true)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {

				this.treatmentStatusArr = [];
				let resp: any = JSON.parse(JSON.stringify(res));

				resp.map(item => {
					if (item.name == '1000' || item.name == '2000') {
						item.patientStatuses.map(patientStatus => {
							this.treatmentStatusArr.push({ "id": patientStatus.id, "option": `${patientStatus.code} - ${patientStatus.description}` });
						});

						if (this.treatmentStatusArr.length > 0) {
														this.patientForm.patchValue({
								patientStatusId: this.treatmentStatusArr[0].id
							})
						}
					}
				})
			},
			(err) => {
				console.log(err)
			}
		)
	}

	getPreferredLocation(elem) {

		this.patientForm.patchValue({
			preferredLocationId: elem.id,
		});

		let doctors: any[] = [];

    this.providerClient.provider_GetProviders(null, elem.id, true, 100, undefined, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				this.preferredDoctorArr = [];
        doctors = JSON.parse(JSON.stringify(res));
        doctors = _.filter(doctors, 'isActive');

        _.each(doctors, d => {
          this.preferredDoctorArr.push({
            'id': d.id,
            'option': `${d.firstName} ${d.lastName}`,
            'nickname': d.nickName
          })
        });

        if (this.preferredDoctorArr.length == 1) {
          let provider = _.head(this.preferredDoctorArr);

		      this.patientForm.patchValue({
            preferredProviderId: provider.id
          });
        }
        else {

		      this.patientForm.patchValue({
            preferredProviderId: this.patient.preferredProviderId,
          });
        }
			},
			(err) => {
				console.log(err)
			}
		)

	}

	getPreferredDoctor(elem) {
		this.patientForm.patchValue({
			preferredProviderId: elem.id,
		});
	}


	backToPrevious(evt) {
		this.onAbandon.emit();
	}

	getInsurance() {

    this.settingsClient.settings_GetInsuranceCompanies()
      .pipe(untilDestroyed(this))
      .subscribe(
		  (res) => {
			this.insuranceCompies = [];
			let insuranceCompanies = _.filter(res, 'isActive');
			_.each(insuranceCompanies, insuranceCompany => {
				let shortName = !insuranceCompany.shortName || !insuranceCompany.shortName.trim() ? insuranceCompany.name : insuranceCompany.shortName;
				this.insuranceCompies.push({
					"id": insuranceCompany.id,
					"shortName": shortName,
					"name": insuranceCompany.name,
					"addressLine1": insuranceCompany.addressLine1,
					"addressLine2": insuranceCompany.addressLine2,
					"city": insuranceCompany.city,
					"state": insuranceCompany.state,
					"zip": insuranceCompany.zip,
					"payorNumber": insuranceCompany.payorNumber
				})
			});
			this.filterInsuranceArr = this.insuranceCompies;
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
			this.insuranceSortType = 'asc';
		  },
		  (err) => {
			  console.log(err)
		  }
	  )
	}

	searchMedicalAlert(_searchkeyword) {
		let searchkeyword: any = _searchkeyword.toLowerCase();
		if (searchkeyword.length > 0) {
			this.filterMdclAlrtArr = this.mdclAlrtArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
			if (this.filterMdclAlrtArr.length == 0) {
				this.filterMdclAlrtArr = this.mdclAlrtArr;
			}

		} else {
			this.filterMdclAlrtArr = [];
		}
	}

	searchTitle(_searchkeyword) {
		let searchkeyword: any = _searchkeyword.toLowerCase();
		if (searchkeyword.length > 0) {
			this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
			if (this.filterTitleArr.length == 0) {
				this.filterTitleArr = this.titleArr;
			}

		} else {
			this.filterTitleArr = this.titleArr;
		}
	}

	onFocusTile(){
		this.filterTitleArr = this.titleArr;
	}

	getMedicalAlert() {
    this.settingsClient.settings_GetMedicalAlerts(null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				this.mdclAlrtArr = res.filter(item => {
					return item.isActive == true
				})
			},
			(err) => {
				console.log(err)
			}
		)
	}

	getPatientDentist(searchobj){

		this.patientsDentist.nativeElement.value = searchobj.dentist.displayName;

		this.patientForm.patchValue({
			dentistId: searchobj.dentist.id,
			dentistClinicId: searchobj.clinic.id
		})
	}

	insuranceCompanySelected(id: number, isForPatient:boolean, rowIndx?:number) {
		let ins = this.insuranceCompies.find((ic) => ic.id == id);

		if(isForPatient){
			this.patientForm.patchValue({
				"insuranceName": ins.shortName,
				"insurance": ins.id,
				"payorId": ins.payorNumber
			});
		} else {

			this.respPartyFormField.controls[rowIndx].patchValue({
				"responsibleInsuranceName": ins.shortName,
				"responsibleInsurance": ins.id,
				"payorId": ins.payorNumber
			})
		}
	}

	searchInsurance(_searchkeyword) {
		let searchkeyword: any = _searchkeyword.toLowerCase();
		if (searchkeyword.length > 0) {
			this.filterInsuranceArr = this.insuranceCompies.filter(option => (option.name.toLowerCase().includes(searchkeyword) || option.shortName.toLowerCase().includes(searchkeyword)));
			if (this.filterInsuranceArr.length == 0) {
				this.filterInsuranceArr = this.insuranceCompies;
			}
		} else {
			this.filterInsuranceArr = this.insuranceCompies;
		}
		this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
		this.insuranceSortType = 'asc';
	}

	sortInsurance(sortOn) {
		this.insuranceSortType = this.insuranceSortType == 'asc' ? 'desc' : 'asc';
		if (sortOn == 'shortname') {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], [this.insuranceSortType]);
		} else {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.name.toLowerCase()], [this.insuranceSortType]);
		}
	}

	onFocusInsurance(){
		this.filterInsuranceArr = this.insuranceCompies;
		this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
		this.insuranceSortType = 'asc';
	}

	getInsurnaceCompanyById(id){
		let insComp = this.insuranceCompies.find((ic) => ic.id == id);
		return insComp;
	}

	getLocationById(locationId){
		let _location = this.preferredPracArr.find(location => location.id == locationId);

		if(_location){
			return _location.option;
		}

		return null;
	}

	getDoctorPreferenceById(doctorId){
		let _doctor = this.preferredDoctorArr.find(doctor => doctor.id == doctorId);
		if(_doctor) {
			return _doctor.nickname;
		}

		return null;
	}

	getLanguageByKey(key){
		let _language = this.LANGUAGES_LIST[key];
		if(_language){
			return _language.name;
		}

		return null;
	}

	getPatientStatus(patientStatusId){

		let patientStatus = this.treatmentStatusArr.find(patientStatus => patientStatus.id == patientStatusId);
		if(patientStatus){
			return patientStatus.option;
		}

		return null;
	}

  getDentistSerarchValue(searchobj){
	this.professionalthank.nativeElement.value = searchobj.dentist.displayName;
		this.patientForm.patchValue({
			referringProfessional: searchobj.dentist.id,
			referringClinicId: searchobj.clinic.id
		})
	}

	getPatientSerarchValue(searchobj){
		this.patientthank.nativeElement.value = `${searchobj.patientModel.firstName} ${searchobj.patientModel.lastName}`;
		this.patientForm.patchValue({
			referringPatient: searchobj.patientModel.patientId
		})
	}

	addNewRespParty(){

		this.respPartyFormField.push(this.formBuilder.group({
			responsiblefname: ['', Validators.required],
			responsiblemiddlename: [''],
			responsiblelastname: ['', Validators.required],
			responsiblePreferred: [''],
			responsibletitle: [''],
			relationshipType: ['', Validators.required],
			responsibleStreet: [''],
			responsibleCity: [''],
			responsibleState: [''],
			responsibleZip: [''],
			responsibledob: [''],
			responsibleGender: ['', Validators.required],
			responsibleSsn: [''],
			responsiblephone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
			responsibleemail: ['', [Validators.email]],
			payorId: [''],
			responsibleInsurance: [''],
			responsibleInsuranceName: [''],
			responsibleGroup: [''],
			responsibleEmpid: [''],
			responsibleEmp: [''],
			responsibleInsuranceNotes: ['']
		}))
	}

	saveAndFinish(){
		let patientFormValue = this.patientForm.value;

		if (!this.patientForm.valid) {
			this.showConfirmation = true;
			this.abandonButton = false;
			if (patientFormValue.patientFirstName == '') {
				this.errorMessage = 'Ooops! \n' +
					'add a first name for this patient.';

			}
			else if (patientFormValue.patientLastName == '') {
				this.errorMessage = 'Ooops! \n' +
					'add a last name for this patient.';

			} else if (patientFormValue.gender == '') {
				this.errorMessage = 'Ooops! \n' +
					'select gender for this patient.';

			} else if (patientFormValue.patientEmail != '' && this.patientForm.get('patientEmail').hasError('email')) {
				this.errorMessage = 'Ooops! \n' +
					'add a valid email for this patient.';
			} else if (patientFormValue.patientCellPhone != '' && this.patientForm.get('patientCellPhone').hasError('pattern')) {
				this.errorMessage = 'Ooops! \n' +
					'add a valid phone for this patient.';
			}

			return;
		} else {

			let errorInRespSection:boolean = false;
			let errorForm;

			let respParties = [];

			this.respPartyFormField.controls.map(respPartyFormItem => {
				if(
					respPartyFormItem.value.responsiblefname == '' &&
					respPartyFormItem.value.responsiblelastname == '' &&
					respPartyFormItem.value.responsibleGender == '' &&
					(respPartyFormItem.value.responsiblephone == '' ||
					respPartyFormItem.value.responsibleemail == '')
				) {
					errorInRespSection = false;

				} else {

					if (
						respPartyFormItem.get('responsiblefname').hasError('required') ||
						respPartyFormItem.get('responsiblelastname').hasError('required') ||
						respPartyFormItem.get('responsibleGender').hasError('required') ||
						respPartyFormItem.get('relationshipType').hasError('required') ||
						respPartyFormItem.get('responsiblephone').hasError('pattern') ||
						respPartyFormItem.get('responsibleemail').hasError('email') ||
						(respPartyFormItem.value.responsibleemail == '' && respPartyFormItem.value.responsiblephone == '')
					) {
						errorForm = respPartyFormItem
						errorInRespSection = true;
						return;
					} else {
						respParties.push(respPartyFormItem.value);
					}
				}
			})

			if (errorInRespSection) {

				this.showConfirmation = true;
				this.abandonButton = false;
				if (errorForm.value.responsiblefname == '') {
					this.errorMessage = 'Ooops! \n' +
						'add a first name for this guardian / parent.';
				}

				if (errorForm.value.responsiblelastname == '') {
					this.errorMessage = 'Ooops! \n' +
						'add a last name for this guardian / parent.';

				} else if (errorForm.value.responsibleGender == '') {
					this.errorMessage = 'Ooops! \n' +
						'select gender for this guardian / parent.';

				} else if (errorForm.value.relationshipType == '') {
					this.errorMessage = 'Ooops! \n' +
						'select relation for this guardian / parent.';

				}else if (errorForm.value.responsibleemail != '' && errorForm.get('responsibleemail').hasError('email')) {
					this.errorMessage = 'Ooops! \n' +
						'add a valid email for this guardian / parent.';
				} else if (errorForm.value.responsiblephone != '' && errorForm.get('responsiblephone').hasError('pattern')) {
					this.errorMessage = 'Ooops! \n' +
						'add a valid phone for this guardian / parent.';
				} else if (errorForm.value.responsibleemail == '' && errorForm.value.responsiblephone == ''){
					this.errorMessage = 'add a valid email / phone for \nthis guardian / parent.';
				}

				return;

			} else {

				if(respParties.length == 0){
					if(patientFormValue.patientEmail == '' && patientFormValue.patientCellPhone == ''){
						this.showConfirmation = true;
						this.abandonButton = false;
						this.errorMessage = 'add a valid email / phone for this patient.';

						return
					}

					this.createPatient(false);

				} else {
					this.createPatient(true, respParties);
				}


			}
		}

	}

	changeSameaddr_chk(_respPartyForm) {

		let patientFormValue = this.patientForm.value;

		_respPartyForm.patchValue({
			responsibleStreet: patientFormValue.patientStreetAd,
			responsibleCity: patientFormValue.patientCity,
			responsibleState: patientFormValue.patientState,
			responsibleZip: patientFormValue.patientZipCode
		})


	}

	createPatient(hasRelation:boolean, respParties?){

		let patientFormValue = this.patientForm.value;

		let primaryAddress = new ContactAddressDto2();
		primaryAddress.addressLine1 = patientFormValue.patientStreetAd;
		primaryAddress.city = patientFormValue.patientCity;
		primaryAddress.state = patientFormValue.patientState;
		primaryAddress.zip = patientFormValue.patientZipCode;

		let patientPhoneDto = new PatientPhoneDto();
		patientPhoneDto.number = this.clearPhones(patientFormValue.patientCellPhone);

		let patientEmailDto = new PatientEmailDto();
		patientEmailDto.emailAddress = patientFormValue.patientEmail;

		let medicalalert = new AlertsDto();
		medicalalert.medical = [patientFormValue.medicalAlert]


		let newPatient:CreatePatientDto = new CreatePatientDto();
		newPatient.patientStatusId = patientFormValue.patientStatusId;
		newPatient.firstName = patientFormValue.patientFirstName;
		newPatient.middleName = patientFormValue.patientMiddleName;
		newPatient.lastName = patientFormValue.patientLastName;
		newPatient.nickname = patientFormValue.patientPreferred;
		newPatient.title = patientFormValue.patientTitle;
		newPatient.primaryAddress = primaryAddress;
		newPatient.dob = patientFormValue.patientdob ? moment(patientFormValue.patientdob).toDate() : null;
		newPatient.gender = patientFormValue.gender;
		newPatient.ssn = patientFormValue.patientSsn;
		newPatient.mobilePhone = patientPhoneDto;
		newPatient.primaryEmail = patientEmailDto;
		newPatient.alerts = medicalalert;
		newPatient.comments = patientFormValue.notes;
		newPatient.dentistId = patientFormValue.dentistId;
		newPatient.dentistClinicId = patientFormValue.dentistClinicId;
		newPatient.referringDentistId = patientFormValue.referringProfessional;
		newPatient.referringClinicId = patientFormValue.referringClinicId;
		newPatient.preferredLocationId = patientFormValue.preferredLocationId;
		newPatient.preferredProviderId = patientFormValue.preferredProviderId;
		newPatient.languageKey = patientFormValue.languageKey;

		this.isLoading = true;

		if(!hasRelation){

			this.locationClient.location_PostPatient(this.selectedLocation.id, newPatient, null).subscribe(resp => {
				this.newPatientData = resp;

        this._store$.dispatch(
          PatientsStoreActions.PatientViewed({
            patientId: resp.id,
            locationId: resp.locationId,
            name: `${resp.firstName} ${resp.lastName}`,
          })
        );

				if (patientFormValue.insurance) {
					let policyDto = new PolicyDto();
					policyDto.insuranceCompanyId = patientFormValue.insurance;
					policyDto.groupPolicyNumber = patientFormValue.groupNumber;
					policyDto.employerId = patientFormValue.employerId;;
					policyDto.employerName = patientFormValue.patientEmp;
					this.contactClient.contact_PostPolicy(this.newPatientData.contactId, policyDto).subscribe(resp => {
						this.showNotification_modal = true;
						this.isLoading = false;
					},
					err => {
						this.isLoading = false;
					})
				} else {
					this.showNotification_modal = true;
					this.isLoading = false;
				}

			},
			err => {
				this.isLoading = false;
			})

		} else {

			this.locationClient.location_PostPatient(this.selectedLocation.id, newPatient, null).subscribe(resp => {
				this.newPatientData = resp;
				this.isLoading = true;

        this._store$.dispatch(
          PatientsStoreActions.PatientViewed({
            patientId: resp.id,
            locationId: resp.locationId,
            name: `${resp.firstName} ${resp.lastName}`,
          })
        );

				if (patientFormValue.insurance) {
					let policyDto = new PolicyDto();
					policyDto.insuranceCompanyId = patientFormValue.insurance;
					policyDto.groupPolicyNumber = patientFormValue.groupNumber;
					policyDto.employerId = patientFormValue.employerId;;
					policyDto.employerName = patientFormValue.patientEmp;
					this.contactClient.contact_PostPolicy(this.newPatientData.contactId, policyDto).subscribe(resp => {
						this.isLoading = false;
						this.saveResponsibleParties(respParties)
					},
					err => {
						this.isLoading = false;
					})
				} else {
					this.saveResponsibleParties(respParties);
				}

			})

		}

	}

	saveResponsibleParties(respParties) {
		let respPartySaveAction = [];

		this.isLoading = true;

		respParties.map(respParty => {

			let _primaryAddress = new ContactAddressDto2();
			_primaryAddress.addressLine1 = respParty.responsibleStreet;
			_primaryAddress.city = respParty.responsibleCity;
			_primaryAddress.state = respParty.responsibleState;
			_primaryAddress.zip = respParty.responsibleZip;

			let relationPhoneDto = new ContactPhoneDto2();
			relationPhoneDto.number = this.clearPhones(respParty.responsiblephone);

			let relationEmailDto = new ContactEmailDto2();
			relationEmailDto.emailAddress = respParty.responsibleemail;

			let relationshipDto: RelationshipDto = new RelationshipDto();
			relationshipDto.firstName = respParty.responsiblefname;;
			relationshipDto.middleName = respParty.responsiblemiddlename;
			relationshipDto.lastName = respParty.responsiblelastname;
			relationshipDto.nickname = respParty.responsiblePreferred;
			relationshipDto.title = respParty.responsibletitle;
			relationshipDto.relationshipType = respParty.relationshipType;
			relationshipDto.primaryAddress = _primaryAddress;
			relationshipDto.dob = respParty.responsibledob ? moment(respParty.responsibledob).toDate() : null;
			relationshipDto.gender = respParty.responsibleGender;
			relationshipDto.ssn = respParty.responsibleSsn;
			relationshipDto.mobilePhone = relationPhoneDto;
			relationshipDto.primaryEmail = relationEmailDto;
			relationshipDto.insuranceCompanyId = respParty.responsibleInsurance;
			relationshipDto.groupPolicyNumber = respParty.responsibleGroup;
			relationshipDto.employerId = respParty.responsibleEmpid;
			relationshipDto.employerName = respParty.responsibleEmp;
			relationshipDto.insuranceNotes = respParty.responsibleInsuranceNotes;

			respPartySaveAction.push(
				this.locationClient.location_PostRelationship(this.selectedLocation.id, this.newPatientData.id, relationshipDto, null)
			);

		})

		forkJoin(respPartySaveAction).subscribe(resp => {
			this.showNotification_modal = true;
			this.isLoading = false;
		},
		err => {
			this.isLoading = false;
		})
	}

	clearPhones(phoneNumber) {
		return phoneNumber.replace(/[()-]/g, '').replace(/\s/g,'')
	}

}
